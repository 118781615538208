import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs';

import { deleteModalFn } from '~/shared/modal/delete/delete-modal';

import { RecordApiService } from './record-api.service';
import { RecordMedicalModel } from './record-medical.model';
import { RecordType } from './record.type';

@Injectable({ providedIn: 'root' })
export class RecordMedicalApiService extends RecordApiService<RecordMedicalModel> {
  override type: RecordType = 'record';

  private deleteModal = deleteModalFn();

  delete(record: RecordMedicalModel) {
    return this.deleteModal({
      message: 'medicalRecord.modal.delete.message',
      context: { value: record.title },
    }).pipe(switchMap(() => this.update(record._id, { status: 'void' })));
  }
}
