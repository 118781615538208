import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppService {
  private _loading$ = new BehaviorSubject({ loading: false, light: false });

  get loading$() {
    return this._loading$.asObservable();
  }

  setLoading(loading: boolean, light: boolean = false) {
    this._loading$.next({ loading, light });
  }
}
